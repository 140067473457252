import { Button, IconButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import "./styles/CreatePartyStepOne.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@tanstack/react-query";
import {
  CREATE_NEW_PARTY_API,
  LOADER_COLOR,
  OPACITY_WHEN_LOADER_ACTIVE,
  SPINNER_SCALE_FACTOR,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { ClimbingBoxLoader } from "react-spinners";

function CreatePartyStepOne() {
  const [file, setFile] = useState(null);
  const [numberOfPeople, setNumberOfPeople] = useState(1);

  const [spinnerSize, setSpinnerSize] = useState(0);
  const [opacity, setOpacity] = useState(1); // initial opacity value

  const navigate = useNavigate();

  const postNewParty = async (newParty) => {
    console.log("Create new party called!");
    console.log(newParty);

    const response = await fetch(CREATE_NEW_PARTY_API, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newParty),
    });

    if (!response.ok) {
      throw new DOMException("failure");
    }

    console.log("Got response");
    console.log(response);

    return response.json();
  };

  const mutation = useMutation({
    mutationFn: postNewParty,
    onSuccess: (data) => {
      onCreateNewPartySuccess(data);
    },
    onError: () => {
      onCreateNewPartyFailure();
    },
  });

  useEffect(() => {
    // Calculate the spinner size based on the screen dimensions
    const newSize =
      Math.min(window.innerWidth, window.innerHeight) * SPINNER_SCALE_FACTOR;
    setSpinnerSize(newSize);

    if (mutation.isLoading) {
      setOpacity(OPACITY_WHEN_LOADER_ACTIVE);
    } else {
      setOpacity(1.0);
    }
  }, [mutation]);

  const handleOnSelect = (e) => {
    console.log("File selected!");
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };

  const clearSelectedFile = () => {
    setFile(null);
    setNumberOfPeople(1);
  };

  const onCreateClick = async () => {
    console.log("Create clicked!");
    const requestBody = await createNewPartyRequestBody();
    mutation.mutate(requestBody);
  };

  const onCreateNewPartySuccess = (returnedData) => {
    const partyId = returnedData.partyId;
    navigate("/partyTime", { state: { partyId: partyId } });
  };

  const onCreateNewPartyFailure = () => {
    console.log("On create failure");
  };

  async function createNewPartyRequestBody() {
    let fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      fileReader.onload = () => {
        const returnObject = {
          masterId: 1234,
          numMembers: numberOfPeople,
          receiptImage: fileReader.result,
        };
        resolve(returnObject);
      };

      fileReader.readAsDataURL(file);
    });
  }

  return (
    <div>
      <div style={{ opacity }}>
        {/* TODO: Consolidate Logo insertion to be common among all flows through a container */}
        <img
          className="CreatePartyStepOne__PayFishLogo"
          src={require("../../assets/img/icon.png")}
          alt="pay.fish logo"
        />
        <Box className="CreatePartyStepOne__OutsideBox">
          <Card variant="outlined" sx={{ borderRadius: "16px" }}>
            <p className="CreatePartyStepOne__DescriptionLabel">
              How it works:
            </p>
            <ul className="CreatePartyStepOne__DescriptionList">
              <li className="CreatePartyStepOne__DescriptionListItem">
                ⬆️ Upload your receipt below
              </li>
              <li className="CreatePartyStepOne__DescriptionListItem">
                🔗 We'll make a personalized link
              </li>
              <li className="CreatePartyStepOne__DescriptionListItem">
                💸 Share and get paid!
              </li>
            </ul>

            {/* Display this when there is a file available */}
            {file && (
              <div>
                <div className="CreatePartyStepOne__FileDescriptionLabel">
                  <p>
                    Selected File: <b>{file.name}</b>
                  </p>
                  <span className="CreatePartyStepOne__DeleteButton">
                    <IconButton onClick={clearSelectedFile}>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </div>
                <img
                  className="CreatePartyStepOne__UploadedFileImage"
                  src={URL.createObjectURL(file)}
                  alt="Uploaded Receipt"
                ></img>
                <div>
                  <p className="CreatePartyStepOne__HowManyPeopleLabel">
                    How many people are in your party?
                  </p>
                  <p className="CreatePartyStepOne__HowManyPeopleSubtitleLabel">
                    This helps pay.fish know how many people to split the charge
                    for extras (tax/tip).
                  </p>
                  <TextField
                    type="number"
                    className="CreatePartyStepOne__HowManyPeopleNumberInput"
                    value={numberOfPeople}
                    InputProps={{
                      inputProps: {
                        max: 10000,
                        min: 1,
                        pattern: "[0-9]*",
                      },
                    }}
                    onChange={(e) => {
                      var value = parseInt(e.target.value, 10);
                      if (isNaN(value)) {
                        setNumberOfPeople(NaN);
                        return;
                      }
                      if (value > 10000) value = 10000;
                      if (value < 1) value = 1;

                      setNumberOfPeople(value);
                    }}
                    onBlur={(e) => {
                      // If focus is lost and the value is empty, then add in a value
                      if (!e.target.value) {
                        setNumberOfPeople(1);
                      }
                    }}
                    label="# of people"
                  />
                </div>
                <Button
                  variant="contained"
                  className="CreatePartyStepOne__UploadButton"
                  component="label"
                  disabled={mutation.isLoading}
                  onClick={onCreateClick}
                >
                  Create
                </Button>
              </div>
            )}

            {/* Display upload button when no file is available */}
            {!file && (
              <div>
                <h2 className="CreatePartyStepOne__HeaderLabel">
                  Upload a receipt:
                </h2>
                <Button
                  variant="contained"
                  className="CreatePartyStepOne__UploadButton"
                  component="label"
                >
                  Select
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleOnSelect}
                    hidden
                  />
                </Button>
              </div>
            )}
          </Card>
        </Box>
      </div>

      {/* Display loader when mutation is loading (user has clicked Create) */}
      {mutation.isLoading && (
        // Render the overlay with the spinner
        <div className="CreatePartyStepOne__SpinnerOverlay">
          <ClimbingBoxLoader
            size={spinnerSize}
            color={LOADER_COLOR}
            loading={mutation.isLoading}
            className="CreatePartyStepOne__Spinner"
          />
        </div>
      )}
    </div>
  );
}

export default CreatePartyStepOne;
