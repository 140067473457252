import {
  IconButton,
  Modal,
  Paper,
  TextField,
  Button,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useRef } from "react";
import { currentModalHeight } from "./ModalUtils";
import CurrencyInput from "react-currency-input-field";

/**
 * The EditExtrasModal spawns when tax or tip amount in a party needs to be updated. It supports only modifying
 * the amount.
 * @param props : property of the modal
 */
function EditExtrasModal(props) {
  const isActive = props.isActive;
  const extraName = props.extraName;
  const extraAmount = props.extraAmount;

  const extraNameFormatted =
    extraName.charAt(0).toUpperCase() + extraName.slice(1);

  const updateActiveStatus = props.updateActiveStatus;
  const submitEditedExtra = props.submitEditedExtra;

  const amountRef = useRef(null);

  return (
    <Modal
      className="CreatePartyStepTwo__EditExtrasModal"
      open={isActive}
      onClose={() => updateActiveStatus(false)}
      aria-labelledby="Edit extra details"
    >
      <Paper
        elevation={3}
        style={{
          borderRadius: "10px",
          height: currentModalHeight(false),
        }}
        className="CreatePartyStepTwo__ModalPaper"
      >
        <IconButton onClick={() => updateActiveStatus(false)}>
          <CloseIcon />
        </IconButton>

        <DialogTitle>{extraNameFormatted}</DialogTitle>

        <CurrencyInput
          className="ViewPartyStepOne__PercentageInput"
          customInput={(props) => (
            <TextField {...props} label={"Price"} inputRef={amountRef} />
          )}
          name={"Price"}
          allowDecimals={true}
          decimalsLimit={2}
          prefix="$"
          inputMode="decimal"
          defaultValue={extraAmount}
        />
        <Button
          className="CreatePartyStepTwo__EditModalSaveButton"
          variant="contained"
          onClick={() => {
            const value = amountRef.current.value;
            if (value !== null && value !== "") {
              submitEditedExtra(extraName, value);
            } else {
              submitEditedExtra(extraName, extraAmount.toString());
            }
          }}
        >
          Save Changes
        </Button>
      </Paper>
    </Modal>
  );
}

export default EditExtrasModal;
