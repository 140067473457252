/* URI CONSTANTS */
const UI_URI_BASE = process.env.REACT_APP_UI_URI_BASE;
const API_URI_BASE = process.env.REACT_APP_API_URI_BASE;

/* ENDPOINT CONSTANTS */
export const CREATE_NEW_PARTY_API = `${API_URI_BASE}/api/party`;
export const GET_EXISTING_PARTY_API = `${API_URI_BASE}/api/party/`; // Append the partyId here
export const GET_EXISTING_PARTY_API_FOR_EDITING =
  API_URI_BASE + "/api/admin/party/"; // Append the partyId here
export const SUBMIT_EDITED_API = API_URI_BASE + "/api/admin/party/updateItem";
export const DELETE_ITEM_API = API_URI_BASE + "/api/admin/party/deleteItem";
export const NEW_ITEM_API = API_URI_BASE + "/api/admin/party/newItem";
export const SUBMIT_EDITED_TAX_API =
  API_URI_BASE + "/api/admin/party/updateTax";
export const SUBMIT_EDITED_TIP_API =
  API_URI_BASE + "/api/admin/party/updateTip";

export const SUBMIT_PAYMENT_API = API_URI_BASE + "/api/payItem";

export const UI_PAY_LINK_BASE = UI_URI_BASE + "/pay/"; // Append the partyId here

/* STATE CONSTANTS */
export const ADMIN_EDITING_MODAL_CLOSED_STATE = -1;
export const ADMIN_ADD_NEW_ITEM_STATE = -2;

/* STRING CONSTANTS */
export const TAX = "tax";
export const TIP = "tip";
export const SHARED = "shared";

/* LOADER CONSTANTS */
export const LOADER_COLOR = "#7336d6";
export const OPACITY_WHEN_LOADER_ACTIVE = 0.3;
export const SPINNER_SCALE_FACTOR = 0.1;
