import {
  IconButton,
  Modal,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import { ADMIN_EDITING_MODAL_CLOSED_STATE } from "../../../constants";
import { currentModalHeight } from "./ModalUtils";
import CurrencyInput from "react-currency-input-field";

/**
 * The EditItemModal spawns when an individual item in a party needs to be edited. It supports editing the
 * name and price of the item.
 * @param props : property of the modal
 */
function EditItemModal(props) {
  const itemIndex = props.editingItemIndex;
  const setEditingItemIndex = props.setEditingItemIndex;
  const submitEditedItem = props.submitEditedItem;

  const itemPrice = props.itemPrice;

  const [modifiedItemName, setModifiedItemName] = useState(
    props.itemName || "",
  );

  const itemPriceRef = useRef(null);

  const inError = !modifiedItemName;

  useEffect(() => {
    setModifiedItemName(props.itemName || "");
  }, [props.itemName, props.itemPrice]);

  return (
    <Modal
      className="CreatePartyStepTwo__EditItemModal"
      open={itemIndex !== ADMIN_EDITING_MODAL_CLOSED_STATE}
      onClose={() => setEditingItemIndex(ADMIN_EDITING_MODAL_CLOSED_STATE)}
      aria-labelledby="Edit item details"
    >
      <Paper
        elevation={3}
        style={{
          borderRadius: "10px",
          height: currentModalHeight(inError),
        }}
        className="CreatePartyStepTwo__ModalPaper"
      >
        <IconButton
          onClick={() => setEditingItemIndex(ADMIN_EDITING_MODAL_CLOSED_STATE)}
        >
          <CloseIcon />
        </IconButton>

        <TextField
          className="ViewPartyStepOne__PercentageInput"
          value={modifiedItemName}
          error={!modifiedItemName}
          helperText={!modifiedItemName ? "Name cannot be empty" : null}
          onChange={(e) => {
            setModifiedItemName(e.target.value);
          }}
          label={"Name"}
        />
        <CurrencyInput
          className="ViewPartyStepOne__PercentageInput"
          customInput={(props) => (
            <TextField {...props} label={"Price"} inputRef={itemPriceRef} />
          )}
          name={"Price"}
          allowDecimals={true}
          decimalsLimit={2}
          prefix="$"
          inputMode="decimal"
          defaultValue={itemPrice}
        />
        {!inError && (
          <Button
            className="CreatePartyStepTwo__EditModalSaveButton"
            disabled={inError}
            variant="contained"
            onClick={() => {
              const value = itemPriceRef.current.value;
              if (value !== null && value !== "") {
                submitEditedItem(itemIndex, modifiedItemName, value);
              } else {
                submitEditedItem(
                  itemIndex,
                  modifiedItemName,
                  itemPrice.toString(),
                );
              }
            }}
          >
            Save Changes
          </Button>
        )}
      </Paper>
    </Modal>
  );
}

export default EditItemModal;
