import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreatePartyStepOne from "./pages/CreateParty/CreatePartyStepOne";
import CreatePartyStepTwo from "./pages/CreateParty/CreatePartyStepTwo";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ViewPartyStepOne from "./pages/ViewParty/ViewPartyStepOne";

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route index element={<CreatePartyStepOne />} />
          <Route path="/partyTime" element={<CreatePartyStepTwo />} />
          <Route path="/pay/:partyId" element={<ViewPartyStepOne />} />
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
