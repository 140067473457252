/** SHARED MODAL FUNCTIONS AND OBJECTS **/

/**
 * Return proper modal height given state parameters.
 * @param inError : flag indicating whether the modal is in an error state
 * @return height string in 'px' amount
 */
export function currentModalHeight(inError: boolean): string {
  if (inError) {
    return "350px";
  } else {
    return "270px";
  }
}
