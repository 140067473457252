import React from "react";

interface Props {
  amount: number;
}

/**
 * The DollarAmount component is a holder parameterized on a float amount to display properly formatted
 * dollar string
 * @param amount - float amount indicating dollar cost
 */
const DollarAmount: React.FC<Props> = ({ amount }) => {
  const formattedAmount = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return <div>{formattedAmount}</div>;
};

export default DollarAmount;
