import { Button, IconButton, Modal, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ConfirmPaymentModal(props) {
  const modalOpenItemId = props.modalOpenItemId;
  const setModalOpenItemId = props.setModalOpenItemId;
  const paymentAmount = props.paymentAmount;
  const partyHost = props.partyHost;
  const partyPaidCallback = props.partyPaidCallback;

  return (
    <Modal
      className="ViewPartyStepOne__ConfirmPaymentModal"
      open={modalOpenItemId !== ""}
      onClose={() => setModalOpenItemId("")}
      aria-labelledby="Confirm payment"
    >
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <IconButton onClick={() => setModalOpenItemId("")}>
          <CloseIcon />
        </IconButton>

        <p className="ViewPartyStepOne__PayMasterDescriptionLabel">
          Please confirm that you have paid{" "}
          <b>
            ${paymentAmount.toFixed(2)} to {partyHost}.
          </b>{" "}
          This will let {partyHost} know that you've paid them!
        </p>
        <br />
        <Button
          variant="contained"
          className="ViewPartyStepOne__PayMasterModalButtons"
          onClick={() => {
            partyPaidCallback();
            setModalOpenItemId("");
          }}
        >
          ✅ I paid ${paymentAmount.toFixed(2)} to {partyHost}
        </Button>
        <Button
          className="ViewPartyStepOne__PayMasterModalButtons"
          onClick={() => setModalOpenItemId("")}
        >
          🙅 I did NOT pay ${paymentAmount.toFixed(2)} to {partyHost}
        </Button>
        <br />
      </Paper>
    </Modal>
  );
}

export default ConfirmPaymentModal;
