/**
 * Helper function that finds item adhering to a specific item index
 *
 * @param partyData data of party
 * @param itemIndex index of the item to find
 * @return appropriate party data
 */
function findItemForIndex(partyData, itemIndex): any {
  const foundItem = partyData.items.find(
    (item) => item.itemIndex === itemIndex,
  );
  return foundItem || null;
}

export { findItemForIndex };
