import { IconButton, Modal, Paper, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function CustomPercentageModal(props) {
  const modalOpenItemId = props.modalOpenItemId;
  const setModalOpenItemId = props.setModalOpenItemId;
  const selectedCustomPercentage = props.selectedCustomPercentage;
  const setSelectedCustomPercentage = props.setSelectedCustomPercentage;
  const setSelectedPercentageForIndex = props.setSelectedPercentageForIndex;
  const items = props.items;

  return (
    <Modal
      className="ViewPartyStepOne__CustomPercentageModal"
      open={modalOpenItemId !== ""}
      onClose={() => setModalOpenItemId("")}
      aria-labelledby="Select custom percentage"
    >
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <IconButton onClick={() => setModalOpenItemId("")}>
          <CloseIcon />
        </IconButton>

        <TextField
          className="ViewPartyStepOne__PercentageInput"
          value={selectedCustomPercentage}
          InputProps={{
            inputProps: {
              max: 100,
              min: 0,
              pattern: "^([1-9][0-9]?|100)$",
              step: 0.1,
              type: "number",
            },
          }}
          onChange={(e) => {
            var value = parseFloat(e.target.value);
            if (isNaN(value)) {
              setSelectedCustomPercentage(NaN);
              setSelectedPercentageForIndex(modalOpenItemId, 0);
              return;
            }
            if (value > 100) value = 100;
            if (value < 0) value = 100;

            setSelectedCustomPercentage(value);
            setSelectedPercentageForIndex(modalOpenItemId, value);
          }}
          onBlur={(e) => {
            // When closing, set this to 100, so that it's reset for next time
            setSelectedCustomPercentage(100);
          }}
          label={"My % of " + items[modalOpenItemId]?.name + " is:"}
        />
      </Paper>
    </Modal>
  );
}

export default CustomPercentageModal;
