import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButtonGroup,
  ToggleButton,
  tableCellClasses,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GET_EXISTING_PARTY_API,
  SHARED,
  SUBMIT_PAYMENT_API,
} from "../../constants";
import CustomPercentageModal from "./pieces/CustomPercentageModal";
import "./styles/ViewPartyStepOne.scss";
import ConfirmPaymentModal from "./pieces/ConfirmPaymentModal";
import DollarAmount from "../../components/DollarAmount";
import { findItemForIndex } from "../utils";

function ViewPartyStepOne() {
  const { partyId } = useParams();
  const [selectedPercentages, setSelectedPercentages] = useState({});
  const [billedItems, setBilledItems] = useState({});
  const [totalBill, setTotalBill] = useState(0.0);

  const [
    customerPercentageModalOpenItemId,
    setCustomerPercentageModalOpenItemId,
  ] = useState("");
  const [selectedCustomPercentage, setSelectedCustomPercentage] =
    useState(100.0);

  const [
    paymentConfirmationModalOpenItemId,
    setPaymentConfirmationModalOpenItemId,
  ] = useState("");

  useEffect(() => {
    recomputeTotalBill();
  }, [selectedPercentages]);

  const { isLoading, error, data } = useQuery({
    queryKey: ["partyData"],
    queryFn: () =>
      fetch(GET_EXISTING_PARTY_API + partyId).then((res) => res.json()),
  });

  const queryClient = useQueryClient();

  const {
    isLoading: paymentSubmissionInProgress,
    error: paymentSuvmissionError,
    data: newPartyData,
    mutate: invokeSubmitPayment,
  } = useMutation({
    mutationFn: (paymentSubmissionPayload) =>
      fetch(SUBMIT_PAYMENT_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentSubmissionPayload),
      }).then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries(["partyData"]);
    },
  });

  // TODO: Improve the isLoading and error handling
  if (isLoading) {
    return (
      <div>
        <p>Loading....</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p>Error! Please try again</p>
      </div>
    );
  }

  function paymentCompleted() {
    // TODO: Make a call to the backend
    console.log("Party fully paid!");

    const numMembers = data.numMembers;
    const additionalTax = data.additionalTaxCharge / numMembers;
    const additionalTip = data.additionalTipCharge / numMembers;

    const payload: any = {
      partyId: partyId,
      payerId: "TBD", // TODO: Determine payerId
      itemPayments: getItemsForUserPayment(), // Assuming this is the items included in the payment
      tax: additionalTax,
      tip: additionalTip,
    };
    invokeSubmitPayment(payload);
  }

  function getItemsForUserPayment() {
    // Compute the total bill
    var validEntries = 0;
    var billedItems = [];
    for (const [key, value] of Object.entries(selectedPercentages)) {
      const rawPercentage = (value as any).percentage;
      const parsedFloat = parseFloat(rawPercentage as string);
      if (!isNaN(parsedFloat)) {
        validEntries++;
        const item = findItemForIndex(data, parseInt(key));
        const itemPrice = parseFloat(item.price);
        const percentage = parsedFloat / 100;
        const subtotal = itemPrice * percentage;
        const paymentAmount = subtotal.toFixed(2);
        const itemObject = {
          itemIndex: key,
          paymentAmount: paymentAmount,
        };
        billedItems.push(itemObject);
      }
    }

    if (validEntries > 0) {
      return billedItems;
    }

    return [];
  }

  function getSelectedPercentageForIndex(index) {
    return selectedPercentages[index];
  }

  function getSelectedPercentageForIndexForToggleButton(index: any) {
    const selectedPercentage = getSelectedPercentageForIndex(index);
    if (!selectedPercentage) {
      return null;
    }

    if (selectedPercentage.isCustom) {
      return "custom";
    }

    return selectedPercentage.percentage;
  }

  function setSelectedPercentageForIndex(index, percentage) {
    // Update the existing percentages
    const defaultPercentages = [
      parseFloat("33.33333"),
      parseFloat("50"),
      parseFloat("100"),
    ];
    const findIndex = defaultPercentages.findIndex(
      (x) => x === parseFloat(percentage),
    );
    const isCustom = findIndex === -1;

    console.log("SETTING SELECTED PERCENTAGE FOR ITEM INDEX " + index);

    if (percentage == null) {
      // This is when the user deselects any option (clear)
      setSelectedPercentages({
        ...selectedPercentages,
        [index]: { percentage, isCustom: false },
      });
      return;
    }

    if (percentage === "custom") {
      // When the user selects custom
      setCustomerPercentageModalOpenItemId(index);
    } else {
      // Set the selected percentage in the dictionary
      setSelectedPercentages({
        ...selectedPercentages,
        [index]: { percentage, isCustom },
      });
    }
  }

  function recomputeTotalBill() {
    // Compute the total bill
    var total = 0.0;
    var validEntries = 0;
    var billedItems = {};
    for (const [key, value] of Object.entries(selectedPercentages)) {
      const rawPercentage = (value as any).percentage;
      const parsedFloat = parseFloat(rawPercentage as string);
      if (!isNaN(parsedFloat)) {
        validEntries++;
        const item = findItemForIndex(data, parseInt(key));
        const itemPrice = parseFloat(item.price);
        const percentage = parsedFloat / 100;
        const subtotal = itemPrice * percentage;
        billedItems[key] = subtotal;
        total += subtotal;
      }
    }

    if (validEntries > 0) {
      const additionalTax = data.additionalTaxCharge;
      const additionalTip = data.additionalTipCharge;
      const numMembers = data.numMembers;
      const additionalChargesPerPerson =
        (additionalTax + additionalTip) / numMembers;
      billedItems[SHARED] = additionalChargesPerPerson;
      total += additionalChargesPerPerson;
    }

    setBilledItems(billedItems);
    setTotalBill(total);
  }

  /**
   * Get the name of a billed item
   * @param index string item index
   * @return name of billed item
   */
  function getBilledItemName(index: string): string {
    if (index === SHARED) {
      return "Shared Costs (Tax, Tip) / Person";
    } else {
      const item = findItemForIndex(data, parseInt(index));
      return item.name;
    }
  }

  function redirectToVenmoAndAskForConfirmation() {
    redirectToVenmoLink();

    // Show an alert here to ask the user for confirmation that they paid
    setPaymentConfirmationModalOpenItemId("OPEN");
  }

  function redirectToVenmoLink() {
    const operatingSystem = getMobileOperatingSystem();

    var venmoLink;

    if (operatingSystem === "iOS") {
      // Load iOS Link
      venmoLink =
        "venmo://paycharge?txn=pay" +
        "&recipients=" +
        data.masterPhoneNumber +
        "&amount=" +
        totalBill;
    } else if (operatingSystem === "Android") {
      // Load Android Link
      venmoLink =
        "intent://paycharge?txn=pay" +
        "&recipients=" +
        data.masterPhoneNumber +
        "&amount=" +
        totalBill +
        "#Intent;scheme=venmo;package=com.venmo;end";
    } else {
      // Load Web Link
      venmoLink = "https://venmo.com";
    }
    window.open(venmoLink, "_blank");
  }

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   * Taken from: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
   * @returns {String}
   */
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <div>
      <img
        className="ViewPartyStepOne__PayFishLogo"
        src={require("../../assets/img/icon.png")}
        alt="pay.fish logo"
      />
      <Box className="ViewPartyStepOne__OutsideBox">
        <Card variant="outlined" sx={{ borderRadius: "16px" }}>
          <p className="ViewPartyStepOne__DescriptionLabel">
            🙇 <b>{data.masterName}</b> ({data.masterPhoneNumber}) has invited
            you to pay:
          </p>
          <img
            className="ViewPartyStepOne__UploadedFileImage"
            src={data.receiptImage}
            alt="Uploaded Receipt"
          ></img>
          <p className="ViewPartyStepOne__DescriptionLabel">
            📋 Select the items that belong to you from the list below:{" "}
          </p>
          <div className="ViewPartyStepOne__TableContainer">
            <Table className="ViewPartyStepOne__Table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Item</TableCell>
                  <TableCell align="center">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.items.map((item) => {
                  return (
                    <Fragment key={item.name}>
                      <TableRow
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableCell align="center">{item.name}</TableCell>
                        <TableCell align="center">
                          <DollarAmount amount={item.price} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          <p className="ViewPartyStepOne__MyPortionLabel">
                            My Portion:{" "}
                          </p>
                          <ToggleButtonGroup
                            color="primary"
                            exclusive
                            className="ViewPartyStepOne__MyPortionToggleButton"
                            value={getSelectedPercentageForIndexForToggleButton(
                              item.itemIndex,
                            )}
                            onChange={(e, value) =>
                              setSelectedPercentageForIndex(
                                item.itemIndex,
                                value,
                              )
                            }
                          >
                            <ToggleButton size="small" value="33.33333">
                              33%
                            </ToggleButton>
                            <ToggleButton size="small" value="50">
                              50%
                            </ToggleButton>
                            <ToggleButton size="small" value="100">
                              100%
                            </ToggleButton>
                            <ToggleButton size="small" value="custom">
                              Custom
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <CustomPercentageModal
            modalOpenItemId={customerPercentageModalOpenItemId}
            setModalOpenItemId={setCustomerPercentageModalOpenItemId}
            selectedCustomPercentage={selectedCustomPercentage}
            setSelectedCustomPercentage={setSelectedCustomPercentage}
            setSelectedPercentageForIndex={setSelectedPercentageForIndex}
            items={data.items}
          />

          <Button className="ViewPartyStepOne__SomethingWrongButton">
            Something wrong or Don't see your item(s)?
          </Button>
          <p className="ViewPartyStepOne__DescriptionLabel">🤑 Your total: </p>
          <h1 className="ViewPartyStepOne__TotalLabel">
            <DollarAmount amount={totalBill} />
          </h1>
          <p className="ViewPartyStepOne__DescriptionLabel">🧾 Breakdown:</p>
          <div className="ViewPartyStepOne__ItemBreakdownTableContainer">
            <Table className="ViewPartyStepOne__Table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Item</TableCell>
                  <TableCell align="center">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(billedItems).map((key) => {
                  return (
                    <Fragment key={key}>
                      <TableRow
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableCell align="center">
                          {getBilledItemName(key)}
                        </TableCell>
                        <TableCell align="center">
                          <DollarAmount amount={billedItems[key]} />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <Button
            variant="contained"
            className="ViewPartyStepOne__PayMasterButton"
            onClick={redirectToVenmoAndAskForConfirmation}
          >
            Pay {data.masterName} on Venmo
          </Button>
          <ConfirmPaymentModal
            modalOpenItemId={paymentConfirmationModalOpenItemId}
            setModalOpenItemId={setPaymentConfirmationModalOpenItemId}
            paymentAmount={totalBill}
            partyHost={data.masterName}
            partyPaidCallback={paymentCompleted}
          />
        </Card>
      </Box>
    </div>
  );
}

export default ViewPartyStepOne;
