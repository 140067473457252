import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment, useState } from "react";
import "./styles/CreatePartyStepTwo.scss";
import {
  GET_EXISTING_PARTY_API_FOR_EDITING,
  UI_PAY_LINK_BASE,
  ADMIN_EDITING_MODAL_CLOSED_STATE,
  SUBMIT_EDITED_API,
  DELETE_ITEM_API,
  SUBMIT_EDITED_TAX_API,
  SUBMIT_EDITED_TIP_API,
  ADMIN_ADD_NEW_ITEM_STATE,
  NEW_ITEM_API,
  TAX,
  TIP,
} from "../../constants";
import DollarAmount from "../../components/DollarAmount";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EditItemModal from "./pieces/EditItemModal";
import EditExtrasModal from "./pieces/EditExtrasModal";
import { findItemForIndex } from "../utils";

function StepTwo() {
  const { state } = useLocation() || {};
  const { partyId } = state || "";

  const [copied, setCopied] = useState(false);

  const [editingItemIndex, setEditingItemIndex] = useState(
    ADMIN_EDITING_MODAL_CLOSED_STATE,
  );
  const [editingItemName, setEditingItemName] = useState("");
  const [editingItemPrice, setEditingItemPrice] = useState("");

  const [isExtraModalStateActive, setIsExtraModalStateActive] = useState(false);
  const [editingExtraName, setEditingExtraName] = useState("");
  const [editingExtraAmount, setEditingExtraAmount] = useState("");

  const queryClient = useQueryClient();

  // TODO: Improve the isLoading and error handling for editing
  const {
    isLoading: isPartyloading,
    error: partyLoadingError,
    data: partyData,
  } = useQuery({
    queryKey: ["partyData"],
    queryFn: () =>
      fetch(GET_EXISTING_PARTY_API_FOR_EDITING + partyId).then((res) =>
        res.json(),
      ),
  });

  const {
    isLoading: isSubmittingEditedItem,
    error: itemSubmitError,
    data: updatedPartyData,
    mutate: invokeEditItem,
  } = useMutation({
    mutationFn: (editedItemPayload) =>
      fetch(SUBMIT_EDITED_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedItemPayload),
      }).then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries(["partyData"]);
    },
  });

  const {
    isLoading: isSubmittingDeletedItem,
    error: itemDeleteSubmitError,
    data: deletedPartyData,
    mutate: invokeDeleteItem,
  } = useMutation({
    mutationFn: (deletedItemPayload) =>
      fetch(DELETE_ITEM_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deletedItemPayload),
      }).then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries(["partyData"]);
    },
  });

  const {
    isLoading: isSubmittingNewItem,
    error: newItemSubmitError,
    data: newPartyData,
    mutate: invokeNewItem,
  } = useMutation({
    mutationFn: (newItemPayload) =>
      fetch(NEW_ITEM_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newItemPayload),
      }).then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries(["partyData"]);
    },
  });

  const {
    isLoading: isSubmittingEditedTax,
    error: taxSubmitError,
    data: updatedTaxData,
    mutate: invokeEditTax,
  } = useMutation({
    mutationFn: (editedTaxPayload) =>
      fetch(SUBMIT_EDITED_TAX_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedTaxPayload),
      }).then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries(["partyData"]);
    },
  });

  const {
    isLoading: isSubmittingEditedTip,
    error: tipSubmitError,
    data: updatedTipData,
    mutate: invokeEditTip,
  } = useMutation({
    mutationFn: (editedTipPayload) =>
      fetch(SUBMIT_EDITED_TIP_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedTipPayload),
      }).then((res) => res.json()),
    onSuccess: () => {
      queryClient.invalidateQueries(["partyData"]);
    },
  });

  function addMissingItemClicked() {
    // Set the state variables for the editing item to be empty
    setEditingItemName("");
    setEditingItemPrice("");
    // Set the editing item index to a value that will trigger the modal to show an empty item
    setEditingItemIndex(ADMIN_ADD_NEW_ITEM_STATE);
  }

  function editButtonClickedForIndex(index) {
    const item = findItemForIndex(partyData, index);
    setEditingItemName(item.name);
    setEditingItemPrice(item.price);
    setEditingItemIndex(index);
  }

  /**
   * Invoke the delete item API upon delete button click
   * @param index  index of the item in the list of items that was clicked
   */
  function deleteButtonClickedForIndex(index) {
    const deletedItemPayload: any = {
      partyId: partyId,
      itemIndex: index,
    };

    console.log("This is the delete item payload:");
    console.log(JSON.stringify(deletedItemPayload));
    invokeDeleteItem(deletedItemPayload);
  }

  /**
   * Setup edit button information for tax changes.
   */
  function editButtonClickedForTax(): void {
    setEditingExtraName(TAX);
    setEditingExtraAmount(partyData.additionalTaxCharge);
    setIsExtraModalStateActive(true);
  }

  /**
   * Setup edit button information for tip changes.
   */
  function editButtonClickedForTip(): void {
    setEditingExtraName(TIP);
    setEditingExtraAmount(partyData.additionalTipCharge);
    setIsExtraModalStateActive(true);
  }

  function submitEditedItem(itemIndex, itemName, itemPrice) {
    console.log("Submit edited item called: " + itemIndex);
    console.log("Submit edited item name called: " + itemName);
    console.log("Submit edited item price called: " + itemPrice);

    if (itemPrice.includes("$")) {
      itemPrice = itemPrice.substring(1);
    }

    const price = parseFloat(itemPrice);

    if (itemIndex === ADMIN_ADD_NEW_ITEM_STATE) {
      // This is a brand new item, submit it
      console.log("Brand new item called!");

      // Submit updated item to the backend
      const newItemPayload: any = {
        partyId: partyId,
        name: itemName,
        price: price,
      };

      invokeNewItem(newItemPayload);
    } else {
      // Submit updated item to the backend
      const editedItemPayload: any = {
        partyId: partyId,
        itemIndex: itemIndex,
        price: price,
        name: itemName,
      };

      console.log("This is the updated edited item payload:");
      console.log(JSON.stringify(editedItemPayload));
      invokeEditItem(editedItemPayload);
    }

    // Close modal
    setEditingItemIndex(ADMIN_EDITING_MODAL_CLOSED_STATE);
  }

  function submitEditedExtra(extraName: string, extraAmount: string) {
    if (extraAmount.includes("$")) {
      extraAmount = extraAmount.substring(1);
    }

    const amount = parseFloat(extraAmount);

    const editedExtraChargePayload: any = {
      partyId: partyId,
      amount: amount,
    };

    if (extraName === TAX) {
      invokeEditTax(editedExtraChargePayload);
    } else if (extraName === TIP) {
      invokeEditTip(editedExtraChargePayload);
    }

    // Close modal
    setIsExtraModalStateActive(false);
  }

  return (
    <div>
      <img
        className="CreatePartyStepTwo__PayFishLogo"
        src={require("../../assets/img/icon.png")}
        alt="pay.fish logo"
      />
      <Box className="CreatePartyStepTwo__ShareOutsideBox">
        <Card variant="outlined" sx={{ borderRadius: "16px" }}>
          <div>
            <h1 className="CreatePartyStepTwo__HeaderLabel">Success ✅</h1>
            <p className="CreatePartyStepTwo__DescriptionLabel">
              📋 Copy and share the link below to get paid.
            </p>
            <textarea
              readOnly
              value={UI_PAY_LINK_BASE + partyId}
              className="CreatePartyStepTwo__LinkTextArea"
            />
            <CopyToClipboard
              text={UI_PAY_LINK_BASE + partyId}
              onCopy={() => setCopied(true)}
            >
              <Button
                variant="contained"
                className="CreatePartyStepTwo__CopyButton"
                component="label"
              >
                Copy Link
              </Button>
            </CopyToClipboard>
            {copied && (
              <div>
                <p className="CreatePartyStepTwo__CopiedLabel">Copied!</p>
              </div>
            )}
          </div>
        </Card>
      </Box>
      {!isPartyloading && !partyLoadingError && (
        <div>
          <Box className="CreatePartyStepTwo__EditOutsideBox">
            <Card variant="outlined" sx={{ borderRadius: "16px" }}>
              <h1 className="CreatePartyStepTwo__HeaderLabel">
                Review your items 👀
              </h1>
              <p className="CreatePartyStepTwo__AnalyzedDescriptionLabel">
                pay.fish has analyzed your receipt and has found the items
                below. Take a look to see if something is missing or needs to be
                changed.{" "}
                <b>
                  The items below are what the people you share the link with
                  will see and pay you for.
                </b>
              </p>
              <div className="CreatePartyStepTwo__TableContainer">
                <Table className="CreatePartyStepTwo__Table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Options</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {partyData.items.map((item) => {
                      return (
                        <Fragment key={item.name}>
                          <TableRow>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center">
                              <DollarAmount amount={item.price} />
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                aria-label="edit"
                                onClick={() =>
                                  editButtonClickedForIndex(item.itemIndex)
                                }
                                disabled={item.hasHadPayments}
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  deleteButtonClickedForIndex(item.itemIndex)
                                }
                                disabled={item.hasHadPayments}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <p className="CreatePartyStepTwo__EditInfoLabel">
                Items with completed payments can't be edited nor deleted.
              </p>
              <Button
                variant="contained"
                className="CreatePartyStepTwo__AddMissingItemButton"
                component="label"
                onClick={addMissingItemClicked}
              >
                Add Missing Item
              </Button>
              <EditItemModal
                editingItemIndex={editingItemIndex}
                setEditingItemIndex={setEditingItemIndex}
                submitEditedItem={submitEditedItem}
                itemName={editingItemName}
                itemPrice={editingItemPrice}
              />
            </Card>
          </Box>
          <Box className="CreatePartyStepTwo__EditExtrasOutsideBox">
            <Card variant="outlined" sx={{ borderRadius: "16px" }}>
              <h1 className="CreatePartyStepTwo__HeaderLabel">
                Review Extra Charges
              </h1>
              <p className="CreatePartyStepTwo__AnalyzedDescriptionLabel">
                Below are tax and tip information you can edit if necessary.
              </p>
              <div className="CreatePartyStepTwo__TableContainer">
                <Table className="CreatePartyStepTwo__Table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Extra Charges</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Options</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Fragment key={TAX}>
                      <TableRow>
                        <TableCell align="center">Tax</TableCell>
                        <TableCell align="center">
                          <DollarAmount
                            amount={partyData.additionalTaxCharge}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editButtonClickedForTax()}
                            disabled={
                              partyData.paymentLog
                                .additionalChargeRemainingTaxBalance <
                              partyData.additionalTaxCharge
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                    <Fragment key={TIP}>
                      <TableRow>
                        <TableCell align="center">Tip</TableCell>
                        <TableCell align="center">
                          <DollarAmount
                            amount={partyData.additionalTipCharge}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editButtonClickedForTip()}
                            disabled={
                              partyData.paymentLog
                                .additionalChargeRemainingTipBalance <
                              partyData.additionalTipCharge
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  </TableBody>
                </Table>
              </div>
              <p className="CreatePartyStepTwo__EditInfoLabel">
                Extras with completed payments can't be edited.
              </p>
              <EditExtrasModal
                updateActiveStatus={setIsExtraModalStateActive}
                submitEditedExtra={submitEditedExtra}
                isActive={isExtraModalStateActive}
                extraName={editingExtraName}
                extraAmount={editingExtraAmount}
              />
            </Card>
          </Box>
        </div>
      )}
    </div>
  );
}

export default StepTwo;
